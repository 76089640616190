import { CanActivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         Router
        } from "@angular/router";

import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate{
    constructor(private _authServ: AuthService, private _router: Router, private _loc: Location){}
    canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        this._authServ.isLoggedIn();
        const isAuth = this._authServ.isLogged.getValue();
        console.log(this._router);
         console.log("isAuth dans la guard",isAuth)
        if(!isAuth){
            console.log("isAuth dans la auth guard",isAuth, " <= Appel logOut");
            this._authServ.logOut();
            return isAuth;
        } else {
            console.log("isAuth dans la auth guard",isAuth, " <= Vous êtes connecté");
            return isAuth;
        }
    }
}