import { Component, OnInit,Pipe} from '@angular/core';

import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-mes-labelisation',
  templateUrl: './mes-labelisations.component.html'
})


export class MesLabelisationsComponent implements OnInit {

  listeLabelisation;
  userInfos;

  structureActuel

  structureSelectionne

  constructor(private _filterServ: FilterService,private _gesServ: MonGesService,private _saiServ: MonSaiService,private _userServ: UserService) {
    
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //console.log("ngOnInit -----> les labelisations");
    this.userInfos = this._userServ.getUserConnected();
    console.log(this.userInfos);
    this._gesServ.setListeLabelisation();
    this._gesServ.getListeLabelisation().subscribe(
        label =>{
          //console.log(label);
          this.listeLabelisation = label;
        }
      )
    var subscribeparam = this._gesServ.getStaticsData().subscribe(
        param =>{
          if(param){
            if(param.Tiers.Structures){
              this.setStructure(param.Tiers.Structures[0])  
            }
            
            
            subscribeparam? subscribeparam.unsubscribe():null;
          }
        }
      )
  }



  ngOnDestroy(){
    
  }

  setStructure(value){
    
    this._gesServ.setStructureSelectionnee(value.id)
    this._saiServ.setStructureSelectionnee(value.id)

    var subscribeparam = this._gesServ.getStaticsData().subscribe(
        param =>{
          if(param){
            this.structureActuel = param.Tiers
            for(var struc of param.Tiers.Structures){
              if(struc.id == param.Tiers.id){
                this.structureSelectionne = struc   
              }
            } 
            //console.log(this.structureSelectionne);
            subscribeparam? subscribeparam.unsubscribe():null;
          }
        }
      )

  }

}


