import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, Observable, pipe } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MonGesService } from 'src/app/services/mon-ges.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { UserService } from 'src/app/services/user.service';
import { debounceTime, share, first, map, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignatureElectroniqueService {

  
  _env=environment;

  headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`
       });
  requestOptions = { headers: this.headers };


  constructor(private _http: HttpClient, private _gesServ : MonGesService, private _userServ : UserService,private _saiServ : MonSaiService) {

  }


  creerProcedureYousign(t : string, param, mail_proc_fini : object, phone? : string){
    return this._gesServ.getStaticsData().pipe(map(res=>{
      console.log(res)
        const info_user = this._userServ.getUserConnected();
        console.log(info_user);
        var hook = {
            "url": this._env.BZ_API_BASE_URL + "signatureelectronique/webhook/format/json",
            "method": "POST",
            "headers": {
                "X-Custom-Header": "Yousign",
                "type" : t,
                "param" : JSON.stringify(param)
            }
        }

        var procedure = {
          "name": "Procédure yousign",
          "description": "Description procédure",
          "start" : false,
          "expiresAt" : new Date(new Date().getTime() +24 * 60* 60000).toISOString(), //date + 1jour
          "members" : [{
            "firstname": info_user.userFirstName.replace("+"," "),
            "lastname": info_user.userLastName.replace("+"," "),
            "email": info_user.userMail,
            "phone": info_user.userTel
          }],
          "subscribers" :[{
            "email" : "administratif@groupebz.fr",
            "group" : "g0"
          }],
          "config": {
            "webhook": {
                "member.finished": [
                    hook
                ],
                "procedure.expired": [
                    hook
                ]
            },
            "email" : {
              "procedure.finished" :[mail_proc_fini]
            }
          }
        }
        console.log(procedure)
        return this._http.post("https://api.yousign.com/procedures", procedure, this.requestOptions)
      }))
  }


  ajouterFichierProcedure(procedureId : string, file : string, nom : string){
    const body = {
          "name": nom ,
          "content": file,
          "procedure": procedureId
      }
    return this._http.post("https://api.yousign.com/files", body, this.requestOptions)
  }

  ajouterSignatureProcedure(fileId, memberId, page_signature : number, position_signature : string){
    
    var body = {
          "file": fileId,
          "member": memberId,
          "page": page_signature,
          "position": position_signature,
          "mention": "Lu et approuvé le " + new Date().toLocaleDateString("fr-FR") + " par"
          
      }
      return this._http.post("https://api.yousign.com/file_objects", body, this.requestOptions)
  }

  startProcedure(procedureId, url, sai?){
    var body  ={
      "start" : true
    }
    this._http.put("https://api.yousign.com" + procedureId, body, this.requestOptions).subscribe(proc =>{
      if(sai){
        console.log("startProcedure SAI");
      this._saiServ.getStaticsData().subscribe(param=>{
          var data = new FormData()
            data.append("params",JSON.stringify(param));
            data.append("proc_id", JSON.stringify(proc["id"]));
            data.append("member_id", JSON.stringify(proc["members"][0]["id"]));
            data.append("file_id", JSON.stringify(proc["files"][0]["id"]));
            data.append("nom_fichier", JSON.stringify(proc["files"][0]["name"]));
            console.log(data);
            this._http.post(this._env.BZ_API_BASE_URL  + url + "/format/json", data,{withCredentials:true}).subscribe()
        })
      }else{
        this._gesServ.getStaticsData().pipe(filter(val => val)).subscribe(param=>{
          var data = new FormData()
            data.append("params",JSON.stringify(param));
            data.append("proc_id", JSON.stringify(proc["id"]));
            data.append("member_id", JSON.stringify(proc["members"][0]["id"]));
            data.append("file_id", JSON.stringify(proc["files"][0]["id"]));
            data.append("nom_fichier", JSON.stringify(proc["files"][0]["name"]));
            this._http.post(this._env.BZ_API_BASE_URL  + url + "/format/json", data,{withCredentials:true}).subscribe()
        })        
      }

    })
  }

  recupererProcedureSignee(proc){
     
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this._env.BZ_TOKEN_YOUSIGN}`,
        'skip' : 'true'
     });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    
    var subscribe_params
     subscribe_params = this._gesServ.getStaticsData().subscribe(
        para => {
          if(para){

            subscribe_params ? subscribe_params.unsubscribe():null;
            console.log(para);
            formData.append("params", JSON.stringify(para));
            this._http.get("https://api.yousign.com"+proc.id, requestOptions).subscribe(
                procSigne =>{
                  let pS: any = {};
                  pS = procSigne;
                  if (pS.status == 'finished') {
                    proc.status = 'finished';
                  }
                }
              )
          }
        }
      )
    
  }


}
