import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { FilterService } from 'src/app/services/filter.service';
import { ContratsService } from 'src/app/services/contrats.service';
import { SignatureElectroniqueService } from 'src/app/services/signature-electronique.service';
import { MonGesService} from 'src/app/services/mon-ges.service';
import { Subscription, interval } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { DownloadService } from 'src/app/services/download.service';
import { UserService } from 'src/app/services/user.service';
import { share, debounceTime,switchMap, takeWhile, take,filter } from 'rxjs/operators';

@Component({
  selector: 'app-mes-contrats-a-signer',
  templateUrl: './mes-contrats-a-signer.component.html'
})
export class MesContratsASignerComponent implements OnInit {

  getMesContrats: Subscription;
  reloadSub: Subscription;
  signContratAction: Subscription;

  mesContratsAsigner;
  selectedContrat
  viewportWidth;

  signContratForm:FormGroup;
  telForm:FormGroup;
  isSubmit=false;

  urlAccesSignature

  attenteSignature

  noContratToshow;

  isMdpVisible = 0;

  userInfo

  paramTiers
  changementTelEnCours;

  pasDeTel = false;

  @ViewChild("signModal",null) modal:ModalDirective;


  constructor(private _filtServ:FilterService,private _contratServ: ContratsService, private _messageServ : MessageService, private _gesServ : MonGesService, private _userServ : UserService, private _sanitizer : DomSanitizer, private _toaster: ToastrService,private _dlServ: DownloadService, private _signatureServ : SignatureElectroniqueService) { 
    // this.reloadSub = this._contratServ.reloadContratsEmitter.subscribe(
    //   evt => {
    //     this.getMesContrats = this._contratServ.getMesContratsAsigner().subscribe(
    //       contrats => {
    //         //console.log(contrats);  
    //         if(!contrats[0]){
    //           this.noContratToshow = contrats[1];
    //         }
    //         this.mesContratsAsigner = contrats;
    //         this.selectedContrat = this.mesContratsAsigner[0].idct;    
    //         this.getMesContrats.unsubscribe();
    //       },
    //       error => {
    //         this.getMesContrats.unsubscribe();
    //       }
    //     )
    //   }
    // )
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.changementTelEnCours = false;
    this.signContratForm = new FormGroup({
      pwd: new FormControl("",[Validators.required]),
      idCt: new FormControl("",[]),
      path: new FormControl("",[]),
      ctSign: new FormControl("",[]),
    })

    this.telForm = new FormGroup({
      tel: new FormControl("",[Validators.required]),
    })
    
    this.userInfo = this._userServ.getUserConnected()
console.log(new Date().toLocaleString("fr-FR"))

    if (!this._gesServ.getStaticsData()){
      var subscribeparam = this._gesServ.getReloadedStaticsData().subscribe(param=>{
        const resultat = JSON.parse(param[0][""]);
        this.paramTiers=resultat.params[0]
        console.log("reload")
        console.log(this.paramTiers)
        subscribeparam?subscribeparam.unsubscribe():null;
      })
    }else{
      var subscribeparam = this._gesServ.getStaticsData().subscribe(param=>{
        if(param){
          this.paramTiers=param
          console.log("static")
          console.log(this.paramTiers)
          subscribeparam?subscribeparam.unsubscribe():null;
        }
      })
    }

    if(!this._contratServ.contratsAsigner.getValue() || !this._contratServ.contratsAsigner.getValue()[0]){
      this._contratServ.SetMesContratsAsigner();

      this.getMesContrats = this._contratServ.getMesContratsAsigner().subscribe(
        contrats => {
          //console.log(contrats);  
          if(contrats && contrats[0] == null){
            this.noContratToshow = "Vous n'avez pas de contrat à signer";
            this.getMesContrats? this.getMesContrats.unsubscribe() : null;
          } else if(contrats && contrats[0] != null){
            this.mesContratsAsigner = contrats;
            //console.log(this.mesContratsAsigner)
            this.selectContrat(this.mesContratsAsigner[0]['contrat.idct']) 
            this.getMesContrats ? this.getMesContrats.unsubscribe() : null;
          }
        },
        error => {
          this.getMesContrats.unsubscribe();
        }
      )
    }else {
      this.mesContratsAsigner = this._contratServ.contratsAsigner.getValue();
      this.selectContrat(this.mesContratsAsigner[0]['contrat.idct']) 
    }
    
  }

  //changementTel

  afficherModal(){
 /*   if(typeof this.paramTiers.Tiers.tel == 'undefined'){
      console.log(true);
    }else{
      this.pasDeTel = false;
    }*/    
    if(!this._userServ.getUserConnected().userTel){
      this.pasDeTel = true;
    }else{
      this.pasDeTel = false;
    }

    this.modal.config.backdrop = 'static'
/*    this.changementTel = false*/
    this.modal.show()
    
  }


/*  changementDeTel(){
    this.changementTel = !this.changementTel
  }*/
  changerNumeroTel(){
    this.pasDeTel = false;
    this.changementTelEnCours= true
  }

  mailChangementDeTel(){
    this._messageServ.changementTel(this.telForm.get("tel").value)
    this.hideModal();
  }


  selectContrat(idct){
    this.selectedContrat = idct;
  }

  showMdp(){
    this.isMdpVisible = this.isMdpVisible == 0  ? 1 : 0;
  }

  hideModal(){
    this.modal.hide()
    this.attenteSignature = false
    this.changementTelEnCours = false
  }

  signContrat(){
    this.changementTelEnCours = false;
    this.attenteSignature = true;
    this.isSubmit = true;
    
    if(!this.signContratForm.valid && this.signContratForm.touched){
      //console.log(this.signContratForm)
      return;

    } else {
      const contratToSign = this.mesContratsAsigner.filter(contrat=> contrat['contrat.idct'] == this.selectedContrat) 
      //console.log(contratToSign)
      this.signContratForm.patchValue({ idCt: contratToSign[0]['contrat.idct'] }); 
      this.signContratForm.get("idCt").updateValueAndValidity();
      this.signContratForm.patchValue({ path: contratToSign[0]['contrat.path'] }); 
      this.signContratForm.get("path").updateValueAndValidity();
      this.signContratForm.patchValue({ ctSign: contratToSign[0]['contrat.ctsign'] }); 
      this.signContratForm.get("ctSign").updateValueAndValidity();
    
    //console.log(this.signContratForm.value)
      /*if(this.userInfo.userMail != 'f.beuzelin@beuzelin.fr'){

        this.signContratAction = this._contratServ.signContrat(this.signContratForm.value).subscribe(
          response => {
            //console.log(response);
            if(response['type'] == "application/json"){
            /*              let dataType = response['type'];
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              //console.log(downloadLink)
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download',contratToSign[0]['contrat.idct'] + ".pdf");
              document.body.appendChild(downloadLink);
              downloadLink.click();

                const curr = this._contratServ.contratsAsigner.getValue();
                const dex = curr.findIndex(ind => ind['contrat.idct'] == this.selectedContrat);
                curr.splice(dex,1);
                this._contratServ.contratsAsigner.next(curr);
                this.mesContratsAsigner = this._contratServ.contratsAsigner.getValue();
                const currNbr = this._contratServ.contratsNbr.getValue();
                this._contratServ.contratsNbr.next(currNbr - 1);
                this._contratServ.contratsNbr.getValue() == 0 ? this.noContratToshow = "Vous n'avez pas de contrat à signer" : null;
                this.signContratForm.reset();
                this.modal.hide();
                this._toaster.success('Votre contrat a bien été signé, il est disponible dans la rubrique "DOCUMENTS". Merci de votre confiance.')
              } else {
                // const curr = this._contratServ.contratsAsigner.getValue();
                // const dex = curr.findIndex(ind => ind['contrat.idct'] == this.selectedContrat);
                // curr.splice(dex,1);
                // this._contratServ.contratsAsigner.next(curr);
                // this.mesContratsAsigner = this._contratServ.contratsAsigner.getValue();
                this._toaster.error("Erreur signature du contrat")
                this.signContratForm.reset();
                this.modal.hide();
            }

          },
          error => {
            //console.log(error)
            this.signContratAction.unsubscribe()
          }
        )
      }else{*/
        this.signContratAction = this._contratServ.signContratElectronique(this.signContratForm.value).subscribe(
          response => {
            //console.log(response);
            if (response['msgerreur'] == ""){
              var param = {
                'ps' : response['ps'],
                'nomCtSign' : response['nomCtSign'],
                'nom_fichier' : response['nom_fichier']
              }
              var mail_fini = {
                "subject" : "Un contrat a été signé !",
                "message" : 'Bonjour, un contrat a été signé, <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Access to documents\">Accéder au contrat</tag><tag data-tag-name="procedure.files" data-tag-type="list"></tag>',
                "to" : ["@subscribers"]
              }
              this._signatureServ.creerProcedureYousign("CONTRATERP1", param, mail_fini).subscribe(
                proc =>{
                  
                  proc.subscribe(procedure=>{
                    this._signatureServ.ajouterFichierProcedure(procedure["id"],response['base64'], response['nom_fichier']).subscribe(file=>{
                      this._signatureServ.ajouterSignatureProcedure(file["id"], procedure["members"][0]["id"], response["nbr_page"], "101,54,241,103").subscribe(signature=>{
                        this._signatureServ.startProcedure(procedure["id"], "administration/ctvalidationelectronique")
                        this.urlAccesSignature = this._sanitizer.bypassSecurityTrustResourceUrl('https://webapp.yousign.com/procedure/sign?members='+ procedure["members"][0]["id"]);
                      
                        this.attenteSignature = true
                        this.recupereFichierSigné(procedure)
                      })
                    })
                   
                  })                  
                })
            }else{
              this._toaster.error(response['msgerreur'])
                this.signContratForm.reset();
                this.modal.hide();
            }

              
          },
          error => {
            //console.log(error)
            this.signContratAction.unsubscribe()
          }
        )
      //}
            

  }
}

recupereFichierSigné(proc){
    interval(1000).pipe(takeWhile(val => this.attenteSignature)).subscribe(
        recherche=>{
          //console.log(proc);
          if(proc.status == 'finished'){              
              const curr = this._contratServ.contratsAsigner.getValue();
                const dex = curr.findIndex(ind => ind['contrat.idct'] == this.selectedContrat);
                curr.splice(dex,1);
                this._contratServ.contratsAsigner.next(curr);
                this.mesContratsAsigner = this._contratServ.contratsAsigner.getValue();
                const currNbr = this._contratServ.contratsNbr.getValue();
                this._contratServ.contratsNbr.next(currNbr - 1);
                this._contratServ.contratsNbr.getValue() == 0 ? this.noContratToshow = "Vous n'avez pas de contrat à signer" : null;
                this.signContratForm.reset();
                this.modal.hide();
                this._toaster.success('Votre contrat a bien été signé, il est disponible dans la rubrique "DOCUMENTS". Merci de votre confiance.')
                this.attenteSignature = false
            }else{
              this._signatureServ.recupererProcedureSignee(proc);              
            }
        }
      )
  }

selectFirst(evt){
  const firstDex = (evt - 1) * 3;
  //console.log(this.mesContratsAsigner)
  this.selectContrat(this.mesContratsAsigner[firstDex]['contrat.idct']) 
  // this.showDetails(this.resutats[0]['analyses'][firstDex]['analyses.identifiant'])
}

  dlContrat(contratId){
    //console.log(contratId)
    const toDl = this.mesContratsAsigner.filter(contrat => contrat['contrat.idct'] == contratId);
    if(toDl.length > 0){
      //console.log(toDl)
      this._dlServ.downloadDocument(contratId,"CT").subscribe(
        response => {
          if(response['type'] == "application/download"){
            let dataType = response['type'];
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            //console.log(downloadLink)
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',"CT" + "-" + contratId + ".pdf");
            document.body.appendChild(downloadLink);
            downloadLink.click();
          } else {
            this._toaster.error("Aucun document trouvé! Echec du téléchargement")
          }
        }
      )
    }
  }

  ngDoCheck(){
    this.viewportWidth = window.innerWidth;
  }

  ngOnDestroy(){
    this.signContratAction ? this.signContratAction.unsubscribe() : null;
    this.getMesContrats ? this.getMesContrats.unsubscribe() : null;
    this.reloadSub ? this.reloadSub.unsubscribe(): null;
  }

}
