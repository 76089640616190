import { Component, OnInit,Pipe, PipeTransform, ViewChild} from '@angular/core';

import { Location } from '@angular/common';
import { Subscription, BehaviorSubject, interval } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FilterService } from 'src/app/services/filter.service';
import { ErrorService } from 'src/app/services/error.service';
import { MonSaiService } from 'src/app/services/mon-sai.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective, MDBModalService} from 'angular-bootstrap-md';



@Component({
  selector: 'app-mon-sai-detail',
  templateUrl: './mon-sai-detail.component.html'
})


export class MonSaiDetailComponent implements OnInit {

  paramList;

  resultatSai;

  countEssentiel
  countIntermediaire
  countAvance

  subscribeQuestion

  @ViewChild("exigenceModal",null) exigenceModal:ModalDirective;

  constructor(private _filterServ: FilterService,private _saiServ: MonSaiService, private _toaster: ToastrService) {
    //console.log("listener mon-sai")
   
  }

  modalAffiche(){
    this.exigenceModal.show();
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    

    this._saiServ.setStaticsData();
    this._saiServ.getStaticsData().subscribe(
        param =>{
          if(param){
            this.paramList = JSON.parse(param[0][""]).params[0]
            console.log(this.paramList)
          }
          
        }
      )
    this._saiServ.setQuestionSai();
    this.subscribeQuestion = this._saiServ.getQuestionSai().subscribe(
      question =>{
        if(question){
          this.calculerScore(question)
          this.subscribeQuestion ? this.subscribeQuestion.unsubscribe() : null;
        }
        
      }
    )
  }

  calculerScore(questions){
    this.countEssentiel = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }
    this.countIntermediaire = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }
    this.countAvance = {
       'nbQuestion' : 0,
       'nbOui' : 0,
       'nbNon' : 0,
       'nbNa' : 0
    }

    for(const theme of questions){ //chaque thématique
      if(theme.SousThematique){ //s'il y a des ous thémtique
        for(const sous_thematique of theme.SousThematique){ //chaque sous thématique
          this.parcourtQuestion(sous_thematique)
        }
      }else{
        this.parcourtQuestion(theme)
      }
    }

    let questionEssentielleRepondue = this.countEssentiel.nbNa + this.countEssentiel.nbOui + this.countEssentiel.nbNon == this.countEssentiel.nbQuestion
    let questionIntermediaireRepondue = this.countIntermediaire.nbNa + this.countIntermediaire.nbOui + this.countIntermediaire.nbNon == this.countIntermediaire.nbQuestion
    let questionAvanceRepondue = this.countAvance.nbNa + this.countAvance.nbOui + this.countAvance.nbNon == this.countAvance.nbQuestion

    //si toutes les questions son répondue
    if(questionEssentielleRepondue && questionIntermediaireRepondue && questionAvanceRepondue ){
      if((this.countEssentiel.nbOui + this.countEssentiel.nbNa) / this.countEssentiel.nbQuestion == 1){
        const res_intermediaire = (this.countIntermediaire.nbOui + this.countIntermediaire.nbNa) / this.countIntermediaire.nbQuestion
        const res_avance = (this.countAvance.nbOui + this.countAvance.nbNa) / this.countAvance.nbQuestion
        if( res_intermediaire == 1 && res_avance >= 0.75){
          this.resultatSai = 'Or'
        }else if(res_intermediaire >= 0.75 && res_avance >= 0.5){
          this.resultatSai = 'Argent'
        }else if(res_intermediaire >= 0.5 && res_avance >= 0){
          this.resultatSai = 'Bronze'
        }else{
          this.resultatSai = 'Non éligible'
        }
      }else{
        this.resultatSai = 'Non éligible'
      }
    }else{
      this.resultatSai = 'Aucun résultat'
    }
  }

  parcourtQuestion(theme){
    for(const question of theme.Tbl_LabelisationQuestionnaire){//parcurt des questions
      if(question.SousQuestion){
        for(const sous_question of question.SousQuestion){
          this.parcourtReponse(sous_question)
        }
      }else{
        this.parcourtReponse(question)
      }
    }
  }

  parcourtReponse(question){
    let count;
    if(question.Complements){
      switch (question.Complements[0].id_ResLabeSaiNiv) {
        case 2://essentiel
          count = this.countEssentiel
          this.countEssentiel.nbQuestion += 1

          break;
        case 3://intermédiaire
          count = this.countIntermediaire
          this.countIntermediaire.nbQuestion += 1

          break;
        case 1: //avancé
          count = this.countAvance
          this.countAvance.nbQuestion += 1
          break;
        
        default:
          // code...
          break;
      }
    }
    
    if(count){


      for(const reponse of question.Tbl_LabelisationQuestionnaireReponses){//parcourt des réponses
        if(reponse.reponse_temp != ""){//récupération de la réponse
          switch (reponse.reponse_TblLabeQuesRepo) {
            case 'Oui':
              count.nbOui += 1
              break;
            case 'Non':
              count.nbNon += 1
              break;
            case 'N/A':
              count.nbNa += 1
              break;          
            default:
              // code...
              break;
          }
        }
      }
    }
  }

  ngOnDestroy(){
    
  }
}


