import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, share, first, map, filter, switchMap } from 'rxjs/operators';
import { Subject, Subscription, Observable, BehaviorSubject, forkJoin, pipe, UnsubscriptionError } from 'rxjs';
import { FilterService } from './filter.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MonGesService {



  constructor(private _http: HttpClient,private _cookieServ: CookieService,private _filtServ: FilterService, private _toaster: ToastrService,private router: Router,private _userServ: UserService) {
    //console.log("constructeur");
    this.setCampagneActive();
    this.setStaticsData();
    
  }

  _env=environment;
  //getInfoTiers:Subscription;
  //getFilterTiers:Subscription;

  /*
    Définition des variables lier à la simulation
  */
  getLisSimulation:Subscription;
  getResultSimulation:Subscription;
  //getList:Subscription;
  maListeSimulation = new Subject<any>();
  private resultatSimulation = new BehaviorSubject<any>(null);
  private listeQuestionSai = new BehaviorSubject<any>(null);
  getFilterHisto: Subscription;
  getFilterResult: Subscription;
  private StructureSelectionnee = new BehaviorSubject<any>(null);

  private paramsList = new BehaviorSubject<any>(null);

  public campagneActive = new BehaviorSubject<any>(null);

  public procedureYouSign  = new BehaviorSubject<any>(null);
  public listeDocument  = new BehaviorSubject<any>(null);
  public listeLabelisation = new BehaviorSubject<any>(null);

  public fichierPac = new BehaviorSubject<any>(null);
  postFilePac:Subscription;
  public numPac = new BehaviorSubject<any>(null);
/*
    Définition des variables lier au questionnaire
  */
  getListSimuQuestion:Subscription;
  getListQuestion:Subscription;
  getListTheme:Subscription;

  //getList:Subscription;
  private maListeSimuQuestion = new Subject<any>();
  private maListeQuestion = new Subject<any>();
  private maListeTheme = new Subject<any>();
  private maListeMatiere = new Subject<any>();  
  getFilterSimuQuestion: Subscription;
  getFilterQuestion: Subscription;
  getFilterTheme: Subscription;
  urlXml = null;


  downloadDocument(url){

    const data = new FormData();
    data.append("url",url);

    return this._http.post(this._env.BZ_API_BASE_URL + "telechargement/viewfichier",data,{withCredentials:true,responseType: 'blob' as 'json'});
  }

  getfileEDI(){
     this._http.post(this._env.BZ_API_BASE_URL + "monges/loadfileedi/format/json", null,{withCredentials:true})
        .subscribe(res => { 
                    if(res){
                      //console.log("haa");
                      //console.log(res);
                      //console.log(JSON.stringify(res));  
                    }
                    

        //send success response
        }, (err) => {
        //send error response
      });    
  }

  setCampagneActive(){
     this._http.post(this._env.BZ_API_BASE_URL + "monges/getcampactive/format/json", null,{withCredentials:true})
        .subscribe(res => { 
                    const resultat = JSON.parse(res[0][""]);
                    this.campagneActive.next(resultat.params[0]['campActive']);

        //send success response
        }, (err) => {
        //send error response
      });
        
  }

  setStructureSelectionnee(structure){
    this.setStaticsData(structure)
    this.StructureSelectionnee.next(structure)
     
  }

  getStructureSelectionnee(){
    return this.StructureSelectionnee.asObservable();
  }

  subscribeParam : Subscription
  getListeDocuments(){
    //this.listeDocument = new BehaviorSubject<any>(null);
    this.setStaticsData();
    
    this.subscribeParam = this.getStaticsData().subscribe(p => {
      if (p){
        //console.log(p);
        const d = new FormData();
        d.append("params", JSON.stringify(p));
        this._http.post(this._env.BZ_API_BASE_URL + "monges/getdocument/format/json", d,{withCredentials:true}).subscribe(
                res =>{
                  const resultat = JSON.parse(res[0][""]);
                  this.listeDocument.next(resultat.params);
                }
          )
        this.subscribeParam ? this.subscribeParam.unsubscribe():null;
      }
    })    
  }

  numeroPac(){
    var subscirbeparam = this.getStaticsData().subscribe(
        para=>{
          if (para){

          var data = new FormData()
          data.append("params",JSON.stringify(para));
           var subscribenum = this._http.post(this._env.BZ_API_BASE_URL + "monges/getnumpac/format/json", data,{withCredentials:true}).subscribe(
            num =>{
              if(num){
                
                if (num["length"] > 0){
                  //console.log(num);
                  this.numPac.next(num[0].numero_pacage_TblLabePacsIlot);    
                }
                
              }
              subscribenum?subscribenum.unsubscribe():null;
            }
           )
           subscirbeparam?subscirbeparam.unsubscribe():null;
        }
      }

      )
    
  }

  mergeDoc(first, last){
    return this.getStaticsData().pipe(filter(val=>val), map(para=>{
      var data = new FormData()
          data.append("params",JSON.stringify(para));
          data.append("first", JSON.stringify(first));
          data.append("last", JSON.stringify(last));
          return this._http.post(this._env.BZ_API_BASE_URL + "monges/setmergepdf/format/json", data,{withCredentials:true})
    }))
          
  }

  getNumPac(){
    return this.numPac.asObservable()
  }

  change100PourcentCollecte(param){
    const d = new FormData();
    d.append("params", JSON.stringify(param));
    this._http.post(this._env.BZ_API_BASE_URL + "monges/setpourcentcollecte/format/json", d,{withCredentials:true}).subscribe(
          num =>{
            
          }
    )
  }

  sendMailErreurSiret(param,siret){
    const d = new FormData();
    d.append("params", JSON.stringify(param));
    d.append("siret", siret);
    this._http.post(this._env.BZ_API_BASE_URL + "monges/sendsiretincorrect/format/json", d,{withCredentials:true}).subscribe(
          num =>{
            console.log(num);
          }
    )
  }

  saveQuestionnaire(thematique, questionnaireFini){
    var theme = {
      Tbl_LabelisationQuestionnaire : thematique.Tbl_LabelisationQuestionnaire,
      id_ResLabeQues : thematique.id_ResLabeQues,
      questionnaireFini : questionnaireFini
    }
    var subscribe_question = this.getStaticsData().subscribe(p => {
      p.Tiers.questionnaire_complet = questionnaireFini
      const d = new FormData();
      d.append("params", JSON.stringify(p));
      d.append("thematique", JSON.stringify(theme));
      this._http.post(this._env.BZ_API_BASE_URL + "monges/savequestion/format/json", d,{withCredentials:true}).subscribe(
          num =>{
            
          }
         )
      subscribe_question?subscribe_question.unsubscribe():null;
      })
  }

  

  getPalierResultatGes(){
    return this._http.post(this._env.BZ_API_BASE_URL + "monges/getpalierresultatges/format/json",{withCredentials:true})
  }


  calculges(){
    return this.getStaticsData().pipe(filter(val=>val),switchMap(p=>{
      if(p){
        //console.log(p)
           const d = new FormData();
            d.append("params", JSON.stringify(p));
        return this._http.post(this._env.BZ_API_BASE_URL + "monges/calculges/format/json",d,{withCredentials:true})
      }
    }))
  }

  setListeLabelisation(){

    this.listeLabelisation = new BehaviorSubject<any>(null);
    this._http.post(this._env.BZ_API_BASE_URL + "monges/getlabelisation/format/json",{withCredentials:true}).subscribe(
        labelisation =>{
          if(labelisation){
            const resultat = JSON.parse(labelisation[0][""]);
            this.listeLabelisation.next(resultat);
          }
        }
      )
  }

  getListeLabelisation(){
    return this.listeLabelisation.asObservable();
  }
  

  setQuestionSai(){
    
    if(!this.getStaticsData()){
      this.setStaticsData()
    }

    var subscribe_question = this.getStaticsData().subscribe(p => {
      if(p){
         const d = new FormData();
          d.append("params", JSON.stringify(p));
          this._http.post(this._env.BZ_API_BASE_URL + "monsai/getsimusai/format/json", d,{withCredentials:true}).subscribe(
              questionnaire =>{
                //console.log(JSON.parse(questionnaire[0][""]))
                this.listeQuestionSai.next(JSON.parse(questionnaire[0][""]));
                
              }
             )
          //subscribe_question ? subscribe_question.unsubscribe():null;
          
      }})
     
  }

  getQuestionSai(){
    return this.listeQuestionSai.asObservable();
  }

  

  getDossierGesSigne(){
    return this.getStaticsData().pipe(filter(val=>val),switchMap(p=>{
      if(p){
        //console.log(p)
         const d = new FormData();
          d.append("params", JSON.stringify(p));
          return this._http.post(this._env.BZ_API_BASE_URL + "monges/recherchedossiergessigne/format/json", d,{withCredentials:true})          
      }
    }))
    
  }

  setStaticsData(struc?){
    var userInfos = this._userServ.getUserConnected();
    const name_user = userInfos.userName.replace("+"," ").split(" ")
    const filterData = new FormData();
    filterData.append("mail", userInfos.userMail);
    filterData.append("prenom", name_user[0]);
    filterData.append("nom", name_user[1]);
    var structure = typeof struc === 'undefined' ? null:struc;
    //si on an'a pas recu de parametre alors recherche dans la liste des structure selectionnée
    if (!structure){
      var subscribestruc=this.getStructureSelectionnee().subscribe(
          struc =>{
            if(struc){
              structure = struc
              subscribestruc ? subscribestruc.unsubscribe():null;
            }
            
          }
        )
    }
    //console.log(structure)
    filterData.append("tiers", structure)
    

    this.paramsList = new BehaviorSubject<any>(null);
     this._http.post(this._env.BZ_API_BASE_URL + "monges/getparams/format/json", filterData,{withCredentials:true})
        .subscribe(res => {
          //console.log(res);
                          const resultat = JSON.parse(res[0][""]);
                          this.paramsList.next(null);
                        this.paramsList.next(resultat.params[0]);
                        //console.log(resultat.params[0])
        //send success response
        }, (err) => {
        //send error response
      });
        
  }

  getStaticsData(){
    return this.paramsList.asObservable();
  }

  getReloadedStaticsData(struc?){
    var userInfos = this._userServ.getUserConnected();
    const name_user = userInfos.userName.replace("+"," ").split(" ")
    const filterData = new FormData();
    filterData.append("mail", userInfos.userMail);
    filterData.append("prenom", name_user[0]);
    filterData.append("nom", name_user[1]);
    var structure = typeof struc === 'undefined' ? null:struc;
    //si on an'a pas recu de parametre alors recherche dans la liste des structure selectionnée
    if (!structure){
      var subscribestruc=this.getStructureSelectionnee().subscribe(
          struc =>{
            if(struc){
              structure = struc
              subscribestruc ? subscribestruc.unsubscribe():null;
            }
            
          }
        )
    }
    //console.log(structure)
    filterData.append("tiers", structure)
    
    return this._http.post(this._env.BZ_API_BASE_URL + "monges/getparams/format/json", filterData,{withCredentials:true})
  }

  loadFilePac(){
    this.getStaticsData().subscribe(p => {
      if (p){
        const filterData = new FormData();
        filterData.append("params",JSON.stringify(p));
        filterData.append("url",this.urlXml);

          if(filterData){
            this.postFilePac = this._http.post(this._env.BZ_API_BASE_URL + "monges/loadfilepacsxml/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
              filePac => {
                        
                       }, error => {
                      this.postFilePac ? this.postFilePac.unsubscribe():null;
                    }
                  )
          }
      }
    })
  }

    //id_ti et dans le tableau des filtres sous le nom de structure
  fileUpload(file_data){

    this.getStaticsData().subscribe(p => {
      if (p){

        file_data.append("params",JSON.stringify(p));
            var getPac =  this._http.post(this._env.BZ_API_BASE_URL + "monges/uploadfile/format/json",file_data,{withCredentials:true})
            .subscribe(res => {//send success response
                  if (res) {
                    if(res['status'] == 1){
                      this.urlXml =  res["name_file"];
                      this.fichierPac.next(res["xml"]); 
                      this.getListeDocuments()
                      this._toaster.success(res["message"])                     
                    }else{
                      this._toaster.error(res["message"])
                    }
                    
                  }                      

          }, (err) => {
          //send error response
              getPac ? getPac.unsubscribe() : null;
        });
      }
    })    

  }

  deleteMatiere(id_AssMatiOrgaLabeTier){
     const filterData = new FormData();
    filterData.append("id_AssMatiOrgaLabeTier",id_AssMatiOrgaLabeTier); 
    this._http.post(this._env.BZ_API_BASE_URL + "monges/deletematiere/format/json",filterData,{withCredentials:true}).subscribe( p => {
    })  
  }

  deleteFile(id_AssDoLabeTiers){
         const filterData = new FormData();
        filterData.append("id_AssDoLabeTiers",id_AssDoLabeTiers); 
        this._http.post(this._env.BZ_API_BASE_URL + "monges/deletefile/format/json",filterData,{withCredentials:true}).subscribe( p => {
          this.getListeDocuments() 
        })  
  }

  getPacFile(){
    return this.fichierPac.asObservable();
  }

  //récuperation de l'historique des actions de simulation
  setGesSimuHisto(){
    this.getStaticsData().subscribe(p => {
      if (p){
        //console.log(p)
        const filterData = new FormData();
        filterData.append("params",JSON.stringify(p));

          if(filterData){
            this.getLisSimulation = this._http.post(this._env.BZ_API_BASE_URL + "monges/getgeshisto/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
              tiersHistoSimu => {
                     /* if(tiersHistoSimu && tiersHistoSimu !== null){*/
                        const res = JSON.parse(tiersHistoSimu[1]);
                        if(res !== null){
                          const resHisto = res.info;
                          this.maListeSimulation.next(resHisto);

                          this.getLisSimulation.unsubscribe();                        
                        }else {
                        //console.log("Aucune simulation d'éligibilité éffectuée.")
                        this.maListeSimulation.next([null,"Aucune simulation d'éligibilité effectuée."]);
                        this.getLisSimulation.unsubscribe();
                        this.getFilterHisto ? this.getFilterHisto.unsubscribe(): null;

                      }

                      
                    }, error => {
                      this.getLisSimulation ? this.getLisSimulation.unsubscribe():null;
                      this.getFilterHisto ? this.getFilterHisto.unsubscribe():null;
                    }
                  )
          }
      }
    })
    
  }

  getGesSimuHisto(){
    return this.maListeSimulation.asObservable();
  }

  getGesSimuResultat(responseResult: string){
    this.resultatSimulation = new BehaviorSubject<any>(null);
   var reponse = {
          "response" : responseResult
        };        
   
      this.getStaticsData().subscribe(
        p => {

          if(p){
            const filterData = new FormData();
            filterData.append("params",JSON.stringify(p));
            filterData.append("reponse",JSON.stringify(reponse));
            this.getFilterResult ? this.getFilterResult.unsubscribe(): null;
              this.getResultSimulation = this._http.post(this._env.BZ_API_BASE_URL + "monges/getsimuresultat/format/json",filterData,{withCredentials:true}).subscribe(
                resultSimu => {
                       /* if(tiersHistoSimu && tiersHistoSimu !== null){*/
                         console.log(resultSimu)
                        this.resultatSimulation.next(resultSimu[0]['resultat_eligibilite_TblGesPreEligGrou'])
                        
                        this.setStaticsData()
                        //result
                        
                      }, error => {
                        this.getResultSimulation ? this.getResultSimulation.unsubscribe():null;
                        this.getFilterResult ? this.getFilterResult.unsubscribe():null;
                      }
                    )
          }
        
      }
    )  
  }


  getResultat(){
    return this.resultatSimulation.asObservable();
  }

  //récupération des question pour le formulaire pré-questionnaire
  setGesSimuQuestion(){
    this.getFilterSimuQuestion = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
      filterContent => {
        const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
        const filterData = new FormData();
        filterData.append("filter",JSON.stringify(filterConf));
        this.getFilterSimuQuestion ? this.getFilterSimuQuestion.unsubscribe(): null;

        if(filterContent){

          this.getListSimuQuestion = this._http.post(this._env.BZ_API_BASE_URL + "monges/getsimuquestion/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
            tiersSimuQuestion => {
                      const res = JSON.parse(tiersSimuQuestion[0][""]);
                      if(res !== null){
                        const resQuestion = res.Question;
                        this.maListeSimuQuestion.next(resQuestion);
                        this.getListSimuQuestion.unsubscribe();                        
                      }else {
                      //console.log("Aucune simulation d'éligibilité éffectuée.")
                      this.maListeSimuQuestion.next([null,"Aucune simulation d'éligibilité éffectuée."]);
                      this.getListSimuQuestion.unsubscribe();
                      this.getFilterSimuQuestion ? this.getFilterSimuQuestion.unsubscribe(): null;

                    }

                    
                  }, error => {
                    this.getListSimuQuestion ? this.getListSimuQuestion.unsubscribe():null;
                    this.getFilterSimuQuestion ? this.getFilterSimuQuestion.unsubscribe():null;
                  }
                )
        }
      }
    )
  } 
  getGesSimuQuestion(){
    return this.maListeSimuQuestion.asObservable();
  }


  SetGesQuestion(){
    this.getStaticsData().subscribe(param => {
      if(param){
        this.getFilterQuestion = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
          filterContent => {
            const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
            const filterData = new FormData();
            filterData.append("filter",JSON.stringify(filterConf));
            filterData.append("params", JSON.stringify(param));
            this.getFilterQuestion ? this.getFilterQuestion.unsubscribe(): null;
            if(filterContent){
              this.getListQuestion = this._http.post(this._env.BZ_API_BASE_URL + "monges/getquestion/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
                tiersQuestion => {
                          const res = JSON.parse(tiersQuestion[0][""]);
                          if(res !== null){
                            const resQuestion = res.Questionnaire;
                            this.maListeQuestion.next(resQuestion);
                            this.getListQuestion.unsubscribe();                        
                          }else {
                          //console.log("Aucune simulation d'éligibilité éffectuée.")
                          this.maListeQuestion.next([null,"Aucune simulation d'éligibilité éffectuée."]);
                          this.getListQuestion.unsubscribe();
                          this.getFilterQuestion ? this.getFilterQuestion.unsubscribe(): null;

                        }

                        
                      }, error => {
                        this.getListQuestion ? this.getListQuestion.unsubscribe():null;
                        this.getFilterQuestion ? this.getFilterQuestion.unsubscribe():null;
                      }
                    )
            }
          }
      )
      }
      
    })
        
  }
  getGesQuestion(){
    return this.maListeQuestion.asObservable();
  }

  SetThemeGes(){
        this.getFilterTheme = this._filtServ.getFilter().pipe(debounceTime(1),share()).subscribe(
        filterContent => {
          const filterConf = this._filtServ.createFilterFromCheckedValues(filterContent);
          const filterData = new FormData();
          filterData.append("filter",JSON.stringify(filterConf));
          this.getFilterTheme ? this.getFilterTheme.unsubscribe(): null;
          if(filterContent){
            this.getListTheme = this._http.post(this._env.BZ_API_BASE_URL + "monges/getthemeges/format/json",filterData,{withCredentials:true}).pipe(share()).subscribe(
              tiersQuestion => {
                        const res = JSON.parse(tiersQuestion[0][""]);
                        if(res !== null){
                          const resQuestion = res.Theme;
                          this.maListeTheme.next(resQuestion);
                          this.getListTheme.unsubscribe();                        
                        }else {
                        //console.log("Aucune simulation d'éligibilité éffectuée.")
                        this.maListeTheme.next([null,"Aucune simulation d'éligibilité éffectuée."]);
                        this.getListTheme.unsubscribe();
                        this.getFilterTheme ? this.getFilterTheme.unsubscribe(): null;

                      }

                      
                    }, error => {
                      this.getListTheme ? this.getListTheme.unsubscribe():null;
                      this.getFilterTheme ? this.getFilterTheme.unsubscribe():null;
                    }
                  )
          }
        }
      )
  }
  getThemeGes(){
    return this.maListeTheme.asObservable();
  }
  
  addMatiere(id_ResMatiOrga,valeur_matiere){
    this.getStaticsData().pipe(debounceTime(1),share()).subscribe(p => {
      if (p){
        const filterData = new FormData();
        filterData.append("params",JSON.stringify(p));
        filterData.append("id_ResMatiOrga",id_ResMatiOrga);
        filterData.append("valeur_matiere",valeur_matiere);

          if(filterData){
            this._http.post(this._env.BZ_API_BASE_URL + "monges/addmatiere/format/json",filterData,{withCredentials:true}).subscribe(
             p => {
              const res = JSON.parse(p[0][""]);
              this.getListeMatiere(res[0]);
            })             
          }
      }
    })
  }

  getListeMatiere(p){
        const d = new FormData();
        d.append("params",p);
        this._http.post(this._env.BZ_API_BASE_URL + "monges/getmatiere/format/json", d,{withCredentials:true}).subscribe(
                res =>{
                  const resultat = JSON.parse(res[0][""]);
                  this.maListeMatiere.next(resultat);
                }
          )
      }
  getMatiere(){
    return this.maListeMatiere.asObservable();
  }      

}
