// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BZ_API_BASE_URL: "https://nx-bz-api.e-bz.fr/",
  BZ_BASE_URL: "https://nx-bz-extranet.e-bz.fr/",

  BZ_INFO_OBJ: {
    318:`Nom courant : Contrat de base
            Principe : Contrat définit une base et un marché de référence.
            Engagement : Volume
            Cahier des charges : Standard

            1ère étape où sont définis :
            – La « base* »
            – Le marché de référence et son/ses échéance(s)*
            – La date limite de fixation du prix
            – Le volume engagé
            – La culture
            – La campagne
            – La période et le lieu d’exécution
            – La date de paiement
            * base : Ecart entre le prix du marché de référence et le prix net producteur. Tient compte du lieu d’exécution.
            * en cas de contrats avec plusieurs échéances, la base peut évoluer. Dans ce cas, pour chaque échéance, la base est précisée au moment de la contractualisation.
            2ème étape :
            Le prix doit être fixé avant une date limite de fixation définie (au plus tard le 15 du mois précédent l’échéance du B-Baz).
            La fixation du prix est possible uniquement entre 11h et 18h lors des jours ouvrables de Matif.
            Le prix net sera égal au prix du marché de référence moins la base.`,
    320: `Nom courant : Contrat à « Prix ferme »
          Principe : Contrat à prix ferme pour une qualité, une période d’exécution, une date de règlement définis.
          Engagement : Volume
          Cahier des charges : Standard

          Sont définis dans le contrat :
          – Le prix ferme net de taxes
          – Le volume engagé
          – La culture
          – La campagne
          – La période et le lieu d’exécution
          – La date de paiement`,
    323: `Nom courant : Contrat “filière”
          Principe : Contrat de filière définit une prime qualité par rapport à un marché de référence.
          Engagement : Surface* ou volume en fonction des offres
          * Dans le cas d’un engagement en surface, le producteur s’engage sur la totalité de la récolte de la surface définie dans le contrat.
          Cahier des charges : Qualités, productions ou variétés spécifiques

          1ère étape où sont définis :
          – La « base » représentant la prime qualité
          – Le marché de référence et son/ses échéance(s)
          – La date limite de fixation du prix
          – Le volume ou la surface engagé(e)
          – La culture
          – La campagne

          – La période et le lieu d’exécution
          – La date de paiement

          * base : Ecart entre le prix du marché de référence et le prix net producteur. Tient compte du lieu d’exécution.
          * en cas de contrats avec plusieurs échéances, la base peut évoluer. Dans ce cas, pour chaque échéance, la base est précisée au moment de la contractualisation.

          2ème étape :
          Le prix doit être fixé avant une date limite de fixation définie (au plus tard le 15 du mois précédent l’échéance du B-Osmoz).
          La fixation du prix est possible uniquement entre 11h et 18h.
          Le prix net sera égal au prix du marché de référence additionné de la prime qualité.`,

    330: `Nom courant : Contrat « édition limitée »
          Principe : Contrat à prix ferme pour une qualité, une période d’exécution, pour un engagement total maximum prédéfini et sur une durée limitée.
          Engagement : Volume
          Cahier des charges : Standard ou spécifique en fonction des offres

          Sont définis dans le contrat :
          – Le prix ferme net de taxes
          – Le volume engagé
          – La culture
          – La campagne

          – La période et le lieu d’exécution
          – La date de paiement

          L’offre étant limitée, un compteur affiche les quantités et le temps restant dans le tableau des offres.`,

    336: `Nom courant : Contrat à « moyenne transparente »
          Principe : Contrat sur lequel une moyenne des cours de clôtures des échéances Matif est calculée pour constituer le prix.
          Engagement : Volume
          Cahier des charges : Standard

          Sont définis dans le contrat :
          – La « base* »
          – Le marché de référence et son/ses échéances
          – Le volume engagé
          – La culture
          – La campagne
          – La période et le lieu d’exécution
          – La date de paiement
          – La période permettant le calcul de la moyenne (en jours ouvrés)
          * base : Ecart entre le prix du marché de référence et le prix net producteur. Tient compte du lieu d’exécution.

          Prix net producteur : Moyenne de toutes les clôtures de matif moins la base.
          * en cas de contrats avec plusieurs échéances, la base peut évoluer. Dans ce cas, pour chaque échéance, la base est précisée au moment de la contractualisation.`,

    343: `Nom courant : Contrat à « Prix objectif »
          Principe : Offre de vente ferme proposée par le producteur, avec un prix et une période de validité choisis par lui-même. La société Beuzelin décide ou non de répondre favorablement à cette proposition.
          Engagement : Volume
          Cahier des charges : Standard
          Sont définis dans le contrat :
          – Le prix d’objectif déterminé par le producteur (prix et pré-engagement ferme et non modifiable) – – Le volume engagé
          – La culture
          – La campagne
          – La période de pré-engagement
          – La période d’exécution
          – La date de paiement

          Pré-engagement sur une période définie par le producteur (date et heure).
          Si durant la période, le prix d’objectif est atteint, le contrat est validé et le producteur en est informé. Si durant la période, le prix d’objectif n’est pas atteint, le contrat est annulé.`,

    347: `Cat ipsum dolor sit amet, mouser. Mouser tiger and lynx. Persian kitten abyssinian and ocelot, but persian.
          Kitty havana brown donskoy. Himalayan. Malkin persian so burmese for cheetah, abyssinian .
          Cheetah himalayan or cheetah so bombay panther, burmese mouser. Siamese donskoy or burmese norwegian forest yet american shorthair.
          Puma thai panther and cougar. Tom sphynx for tabby for american shorthair havana brown. Ocicat.
          iamese panther or munchkin yet leopard cornish rex so devonshire rex tabby. British shorthair persian cougar but burmese bobcat so malkin singapura.
          Egyptian mau himalayan abyssinian so sphynx yet ragdoll or sphynx. Tomcat kitty lynx or lynx scottish fold.`,

    345: `Cat ipsum dolor sit amet, thinking longingly about tuna brine yet have a lot of grump in yourself
          because you can't forget to be grumpy and not be like king grumpy cat. Scratch the furniture if it smells like
          fish eat as much as you wish humans,humans, humans oh how much they love us felines we are the center of attention they feed, they clean .
          Have my breakfast spaghetti yarn. Small kitty warm kitty little balls of fur. Cat slap dog in face rub butt on table annoy kitten brother with poking flee
          in terror at cucumber discovered on floor. Shove bum in owner's face like camera lens lick face hiss at owner, pee a lot, and meow repeatedly scratch at
          fence purrrrrr eat muffins and poutine until owner comes back or love blinks and purr purr purr purr yawn humans,humans,
          humans oh how much they love us felines we are the center of attention they feed, they clean for i like to spend my days sleeping and eating
          fishes that my human fished for me we live on a luxurious yacht, sailing proudly under the sun, i like to walk on the deck, watching the horizon,
          dreaming of a good bowl of milk make it to the carpet before i vomit mmmmmm.
          Roll on the floor purring your whiskers off run outside as soon as door open.
          You are a captive audience while sitting on the toilet, pet me meowwww, so slap kitten brother with paw cat snacks,
          lick sellotape or i heard this rumor where the humans are our owners, pfft, what do they know?!
          for i hate cucumber pls dont throw it at me.`,
    23:`Cat ipsum dolor sit amet, culpa reprehenderit or laboriosam laboris. Totam mollit, ad officia. Ipsa magni mollit or et yet in exercitation. Eaque iste but nisi but magna eius for veritatis. Laboriosam voluptate but accusantium so explicabo iste and sed. Dolore autem, dolorem. Ullamco aute quo. Amet do. Adipisicing omnis eiusmod consequuntur. Numquam doloremque but non omnis so natus architecto for nisi. Tempor excepteur iste lorem, incidunt yet deserunt. In cillum yet do yet quam for ut, totam amet.
        Ullam adipisicing nostrum. Veritatis et yet consectetur or ab veritatis for sequi. Consequat nemo. Rem ab so aliquid and quasi so eu. Deserunt eum yet non. Est dicta ullam.`



  },
  BZ_PRODUCTS_NAMES : [ "BLE","FEVEROLE", "POIS CHICHE", "ORGE", "BLE DUR", "MAIS", "POIS", "AVOINE", "COLZA" ],
  BZ_TOKEN_YOUSIGN : "34acb4981cb2f25bc4cc633ed0607527"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
